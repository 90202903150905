//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-932:_8792,_3952,_3879,_6240,_6132,_392,_8760,_7112;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-932')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-932', "_8792,_3952,_3879,_6240,_6132,_392,_8760,_7112");
        }
      }catch (ex) {
        console.error(ex);
      }